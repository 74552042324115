<template>
  <!-- v-show="$store.getters.getOpenLateralMenuAccount" -->
  <div class="menu_lateral_principal grid-cols-2" style="background: #810042;" >
      <div class="sticky top-0 items-center space-x-4 mb-5">
        <div>
          <div class="py-5" v-if="accountType=='administrator' || accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor'">

            <img v-show="load" src="@/assets/user-ico.svg" class="w-10 h-10 md:w-20 md:h-20 mx-auto" />
            <div v-show="!load" class="animate-pulse rounded-full bg-white h-10 w-10 md:h-16 md:w-16 mx-auto"></div>

            <div v-show="load">
              <div class="grid grid-cols-1 mt-5">
                <div class="mb-3">
                  <div class="grid grid-cols-4">
                    <p class="flex col-span-3 text-white text-xs md:text-sm ml-2 md:ml-4 justify-start items-center font-bold">Nombre de usuario</p>
                    <div class="flex col-span-1 dropdown text-right justify-center items-center">
                      <span><i class="arrow down"></i></span><br>
                      <div class="dropdown-content left-0 w-full">
                          <ul class="">
                            <li style="padding:7px 10px 7px 10px;border-bottom:1px solid #810042">
                              <img src="https://icongr.am/entypo/key.svg?size=82&color=810042" style="float:left;width:20px;height:20px;margin-right:5px;" alt="key" loading="lazy">
                              <router-link :to="`/${account}/cambiar-contrasena`" class="font-light text-cherryColor text-base font-weight-md">Cambiar Contraseña</router-link>
                            </li>
                            <li style="padding:7px 10px 7px 10px;">
                              <img src="https://icongr.am/entypo/camera.svg?size=82&color=810042" style="float:left;width:20px;height:20px;margin-right:5px;" alt="key" loading="lazy">
                              <a href="#" class="font-light text-cherryColor text-base font-weight-md">Cambiar Imagen de Perfil</a>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>

                  <p class="text-white text-xs md:text-sm ml-2 md:ml-4">{{fullName}}</p>
                </div>
                <div class="mb-3">
                  <p class="text-white text-xs md:text-sm ml-2 md:ml-4 font-bold">Puesto</p>
                  <p class="text-white text-xs md:text-sm ml-2 md:ml-4">{{role}}</p>
                </div>
              </div>
            </div>

            <div v-show="!load">
              <div class="p-4 max-w-sm w-full mx-auto">
                <div class="animate-pulse flex space-x-4">
                  <div class="flex-1 space-y-6 py-1">
                    <div class="space-y-3">
                      <div class="grid grid-cols-3 gap-4">
                        <div class="h-2 bg-white rounded col-span-1"></div>
                      </div>
                      <div class="h-2 bg-white rounded"></div>
                    </div>

                    <div class="space-y-3">
                      <div class="grid grid-cols-3 gap-4">
                        <div class="h-2 bg-white rounded col-span-1"></div>
                      </div>
                      <div class="h-2 bg-white rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-5 pt-10" v-if="role == 'Cliente'">
            <p class="roboto-regular text-2xl text-white">Menú</p>
          </div>
          <ul class="space-y-2 text-sm accordion-menu mt-6 animate-fade-down">
              
              <li class="hover:bg-panelHover hover:text-cherryColor px-2" >
                <router-link :to="`/${account}/principal`" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor">
                  <span class="flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>  
                  </span>
                  <div class="col-span-4 dropdownlink w-full">
                    <span class="titleMenu">Inicio</span>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </router-link>
              </li>

              <li class="hover:bg-panelHover hover:text-cherryColor px-2" v-if="accountType == 'member' || accountType == 'cabsa_member'">
                <div @click="goToLoan()" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor">
                  <span class="flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                    </svg>                    
                  </span>
                  <div class="col-span-4 dropdownlink w-full">
                    <span class="titleMenu">Préstamo</span>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </li>

              <li class="hover:bg-panelHover hover:text-cherryColor px-2" v-if="accountType=='administrator'">
                <router-link :to="`/${account}/generar-referencias`" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor">
                  <span class="flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                    </svg>                    
                  </span>
                  <div class="col-span-4 dropdownlink w-full">
                    <span class="titleMenu">Generar Referencias</span>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </router-link>
              </li>

              <li class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2" v-if="accountType=='issste_enroller' || accountType=='administrator' || accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor'">
                
                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg> 
                </span>
                  
                <div class="col-span-4 dropdownlink w-full"> 
                  <i class="fa fa-road" aria-hidden="true" d="menu_my_dates"></i>
                  <span>Inscripción Asistida</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-5 submenuItems block">
                  <li id="menu_complete_registration" class="w-full hover:bg-cherryColor">
                    <router-link :to="`/${account}/captura-asistida/seccion/uno`" >FONCABSA</router-link>
                  </li>
                  <li id="menu_upload_documents" class="w-full hover:bg-cherryColor">
                    <router-link :to="`/${account}/captura-asistida/sinaptissste/seccion/uno`">SINAPTISSSTE</router-link>
                  </li>
                </ul>

              </li>

              <li class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2" v-if="accountType=='administrator' || accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor'">
                
                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                  </svg>
                </span>

                <div class="col-span-4 dropdownlink w-full"> 
                  <i class="fa fa-road" aria-hidden="true" d="menu_my_dates"></i>
                  <span>Reportes</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-5 submenuItems">
                  <li id="menu_upload_documents" class="w-full hover:bg-cherryColor">
                    <router-link :to="`/${account}/reportes/layouts/socios`" >Layout Altas ITBNKS</router-link>
                  </li>
                  <li id="il_configuration_folio" class="w-full hover:bg-cherryColor">
                    <router-link :to="`/${account}/itbanks/configuracion/folio`" >Configuración de Folios Clientes ITBNKS</router-link>
                  </li>
                </ul>

              </li>


              <li class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2" v-if="accountType=='administrator' || accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor' || accountType=='enroller' || accountType == 'financial_advisor' ">
                
                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                  </svg>        
                </span>

                <div class="col-span-4 dropdownlink w-full" @click="open_hamburger('products')"> 
                  <i class="fa fa-road" aria-hidden="true" d="menu_my_dates"></i>
                  <span>Productos</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-5 submenuItems">
                  <li v-if="false" class="w-full hover:bg-cherryColor">
                    <router-link :to="`/${account}/productos/vida-simple`">Vida Simple</router-link>
                  </li>
                  <li class="w-full hover:bg-cherryColor">
                    <router-link :to="`/${account}/productos/vida-total`">Vida Total</router-link>
                  </li>
            
                </ul>

              </li>



              <li class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2" v-if="accountType=='member_analyst' || accountType=='loan_analyst' || accountType=='supervisor'">
                
                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                  </svg>        
                </span>

                <div class="col-span-4 dropdownlink w-full"> 
                  <i class="fa fa-road" aria-hidden="true" d="menu_my_dates"></i>
                  <span>Pagos</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-5 submenuItems">
                  <li class="w-full hover:bg-cherryColor">
                    <router-link :to="`/${account}/pagos/inscripcion`">Pagos de inscripción</router-link>
                  </li>
                  <li class="w-full hover:bg-cherryColor">
                    <router-link :to="`/${account}/pagos/aportacion`">Pagos de aportación</router-link>
                  </li>
            
                </ul>

              </li>


              <!-- <li v-if="accountType=='administrator'" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2">

                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                  </svg>         
                </span>       

                <div class="col-span-4 dropdownlink w-full"> 
                  <i class="fa fa-road" aria-hidden="true" d="menu_my_dates"></i>
                  <span>Comisiones</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-5 submenuItems block">
                  <li id="menu_commission_scheme" class="w-full hover:bg-cherryColor" ><router-link :to="`/${account}/commissions/newcommissionscheme`">Nuevo esquema de comisiones</router-link></li>
                  <li id="menu_capture_individual_product_bonus_scheme" class="w-full hover:bg-cherryColor" ><router-link :to="`/${account}/commissions/capturebonusscheme`">Capturar Esquema de Bono Individual por Producto</router-link></li>
                  <li id="menu_assign_scheme_commissions" class="w-full hover:bg-cherryColor" ><router-link :to="`/${account}/commissions/assignschemecommissions`">Asignar Esquema de Comisiones Individual</router-link></li>
                  <li id="ul_menu_capture_period" class="w-full hover:bg-cherryColor" ><router-link to="/commissions/SetUpProductCommissionPayments">Configurar pagos de comisiones de productos</router-link></li>
                  <li id="ul_menu_capture_period" class="w-full hover:bg-cherryColor" ><router-link to="/commissions/recordperiodpaymentcommissions">Captura un período para pago de comisiones</router-link></li>
                </ul>

              </li> -->

              <li v-if="accountType=='loan_analyst' || accountType=='member_analyst'" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2">
                
                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                  </svg>
                </span>

                <div class="col-span-4 dropdownlink w-full"> 
                  <i class="fa fa-road" aria-hidden="true" d="menu_my_dates"></i>
                  <span>Créditos</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-4 submenuItems">
                  <li id="menu_loans" class="w-full hover:bg-cherryColor" ><router-link :to="`/${account}/clientes/credito`">Candidatos a Créditos</router-link></li>
                </ul>

              </li>

              <li class="hover:bg-panelHover hover:text-cherryColor px-2"  v-if="accountType=='member_analyst' || accountType=='supervisor' || accountType=='loan_analyst'">
                <router-link :to="`/${account}/referralhistory`" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor">
                  <span class="flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg> 
                  </span>
                  <div class="col-span-4 dropdownlink w-full">
                    <span class="titleMenu">Historial de referidos</span>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </router-link>
              </li>

              <!-- cliente -->

              <li class="hover:bg-panelHover hover:text-cherryColor px-2" v-if="accountType == 'member' || accountType == 'cabsa_member'" id="menu_change_password"  >
                <router-link :to="`/cuenta/cambiar-contrasena`" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor">
                  <span class="flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                    </svg>                    
                  </span>
                  <div class="col-span-4 dropdownlink w-full">
                    <span class="titleMenu"> Cambiar contraseña</span>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </router-link>
              </li>

              <li v-if="accountType == 'member' || accountType == 'cabsa_member'" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2" v-show="admissionRequestStatus">

                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                  </svg>                  
                </span>
                
                <div class="col-span-4 dropdownlink w-full"> 
                  <i class="fa fa-road" aria-hidden="true" d="menu_my_dates"></i>
                  <span>Mis Datos</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-5 submenuItems block">
                  <li id="menu_complete_registration" class="w-full hover:bg-cherryColor">
                    <router-link to="/cuenta/completar-registro"
                      >Completa tu registro</router-link
                    >
                  </li>
                  <li id="menu_upload_documents" class="w-full hover:bg-cherryColor">
                    <router-link to="/cuenta/carga-documentos/1"
                      >Carga de documentos</router-link
                    >
                  </li>

                </ul>

              </li>

              <li v-if="(accountType == 'member' || accountType == 'cabsa_member') && missingData.length > 0 && organizations.includes('sinaptissste')" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2">

                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                  </svg>                  
                </span>

                <div class="col-span-4 dropdownlink w-full" > 
                  Registra tus datos <span  class="inline-block w-6 h-6 rounded-full bg-green-400 text-white text-center">{{ missingData.length }}</span>
                  <br />
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-5 submenuItems block">
                  <li v-for="(module) in missingData" :key="module != null? module.module_name: ''" :id="module != null? module.module_name.toString().toLowerCase().replaceAll(' ','_'): ''" class="w-full hover:bg-cherryColor">
                    <router-link v-if="module != null" class="normal-case" :to="module.route">{{ module != null? module.module_name:'' }}</router-link>
                  </li>
                </ul>
              </li>

              <li v-if="accountType == 'member' || accountType == 'cabsa_member'" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2">

                <span class="flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                  </svg>
                </span>

                <div class="col-span-4 dropdownlink w-full" >
                  <i class="fa fa-road" aria-hidden="true"></i> 
                  <span>Referidos</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>

                <ul class="col-span-5 submenuItems block">
                  <li id="menu_complete_registration" class="w-full hover:bg-cherryColor">
                    <router-link to="/cuenta/referidos/referir-persona"
                      >Referir Persona</router-link
                    >
                  </li>
                  <li id="menu_upload_documents" class="w-full hover:bg-cherryColor">
                    <router-link to="/cuenta/referidos"
                      >Historial de Referidos</router-link
                    >
                  </li>
                </ul>
            </li>


            <li v-if="clientId != 0 && (accountType == 'member' || accountType == 'cabsa_member') && !organizations.includes('sinaptissste')" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2">
              
              <span class="flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>                 
              </span>            

              <div class="col-span-4 dropdownlink w-full" >
                <i class="fa fa-road" aria-hidden="true"></i> 
                <span>Actualiza tus datos</span>
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </div>

              <ul class="col-span-5 submenuItems block">
                <li class="w-full hover:bg-cherryColor">
                  <router-link class="normal-case" to="/cuenta/update-personal-data/rfc">RFC</router-link>
                </li>
                <li class="w-full hover:bg-cherryColor">
                  <router-link class="normal-case" to="/cuenta/update-personal-data/update-ine">Actualizar Identificación</router-link>
                </li>
                <li class="w-full hover:bg-cherryColor">
                  <router-link class="normal-case" to="/cuenta/update-personal-data/adress">Dirección</router-link>
                </li>
                <li class="w-full hover:bg-cherryColor">
                  <router-link class="normal-case" to="/cuenta/update-personal-data/marital-status">Estado Civil</router-link>
                </li>
                <li class="w-full hover:bg-cherryColor">
                  <router-link class="normal-case" to="/cuenta/update-personal-data/work-informarion">Laboral</router-link>
                </li>
                <li class="w-full hover:bg-cherryColor">
                  <router-link class="normal-case" to="/cuenta/update-personal-data/bank-information">Información Bancaria</router-link>
                </li>
                <li class="w-full hover:bg-cherryColor">
                  <router-link class="normal-case" to="/cuenta/update-personal-data/contact">Contacto</router-link>
                </li>
                
              </ul>

            </li>

            <li v-if="clientId != 0 && (accountType == 'member' || accountType == 'cabsa_member') && !organizations.includes('sinaptissste')" class="grid grid-cols-5 hover:bg-panelHover hover:text-cherryColor px-2">
              
              <span class="flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>                
              </span>

              <div class="col-span-4 dropdownlink w-full"> 
                <router-link to="/cuenta/beneficiaries"
                  >Actualizar beneficiarios</router-link
                >
                <br />
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </div>

            </li>

             <!-- cliente fin -->
              <li class="hover:bg-panelHover hover:text-cherryColor px-2" >
                <a v-on:click="logout" class="grid grid-cols-5 md:items-center text-white hover:text-cherryColor font-medium">
                  <span class="flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                  </span>
                  <div class="col-span-4 dropdownlink cursor-pointer">
                    <span class="titleMenu">Cerrar Sesión</span>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </a>
              </li>
          </ul>
      </div>
    </div>
  </div>
  </template>

  <script>

  import { getUserInfo, getTokenDecoden } from "@/helpers/tokenauth";
  import { getAdmissionRequestOrCreate, createMissingDataArray, getMissingData } from "@/api/user";
  import { useRouter } from "vue-router";
  import { computed, onBeforeMount, ref } from "vue";
  import { useStore } from "vuex";
  import Swal from "sweetalert2";

  export default {
      name: 'MenuLateral',
      setup() {
        const fullName = ref("");
        const role = ref("");
        const accountType = ref("");
        const account =ref("");
        const load = ref(false);
        const clientId = ref(0);
        const personId = ref(null);
        const admission_status = ref("");
        const admissionRequestStatus = ref(false);
        const router = useRouter();
        const store = useStore();
        const admission_registration_date = ref();

        const menu_items = ref({
          report:false,
          commission:false,
          products:false
        });

        const missingData = computed(()=> {
          return store.getters.getCountMissingDataNumber;
        });

        const isLoanCandidate = computed(()=> {
          return store.getters.getIsCandidate;
        });

        const organizations = ref([]);

        onBeforeMount(async()=> {
          store.dispatch('setAvailableForRegistration', false);

          loadInfo();
          menuAccordion();

          if(organizations.value.includes("sinaptissste")){
            let missingDataList = getMissingDataFromStore();

            if(missingDataList.length == 0 && clientId.value > 0){
              await getMisingDataArray();
            }
          }

          isLoanCandidate.value = getIsCandidateStore();        
        });

        function menuAccordion(){
          $(function() {
              var Accordion = function(el, multiple) {
                  this.el = el || {};
                  // more then one submenu open?
                  this.multiple = multiple || false;

                  var dropdownlink = this.el.find('.dropdownlink');
                  dropdownlink.on('click',
                                  { el: this.el, multiple: this.multiple },
                                  this.dropdown);
              };

              Accordion.prototype.dropdown = function(e) {
                  var $el = e.data.el,
                      $this = $(this),
                      //this is the ul.submenuItems
                      $next = $this.next();

                  $next.slideToggle();
                  $this.parent().toggleClass('open');

                  if(!e.data.multiple) {
                  //show only one menu at the same time
                      $('ul.space-y-2.text-sm.accordion-menu').find('.submenuItems').not($next).slideUp().parent().removeClass('open');
                  }
              }

              var accordion = new Accordion($('.accordion-menu'), false);
          });
        }

        function getMissingDataFromStore(){
          return store.getters.getCountMissingDataNumber;
        }

        function getIsCandidateStore(){
          return store.getters.getIsCandidate;
        }

        async function getMisingDataArray(){
          let missingData = await getMissingClientData();

          if(missingData != null){
            let adsMissingData = await createMissingDataArray(missingData);
            store.dispatch("setCountMissingDataAction", {value: adsMissingData});
          }
        }
        
        async function getMissingClientData(){
          return await getMissingData(token, clientId.value, "new_client").then(resp => (!resp.data.response.has_error)? resp.data.response.result: null).catch(err => null);
        }

        function logout(){
          sessionStorage.clear();
          router.push({path:"/"});
        }

        function loadInfo() {
            let dataAccount = getUserInfo()
            account.value = dataAccount.account;
            fullName.value = dataAccount.full_name;
            role.value = dataAccount.role;
            accountType.value = dataAccount.account_type;
            clientId.value = dataAccount.client_id;

            let auth = getTokenDecoden();
            organizations.value = auth.obj.organization_list;
            // console.log("🚀 ~ file: LateralMenu.vue:427 ~ loadInfo ~ organizations.value:", organizations.value)

            getDataClient();

            setTimeout(() => {
              load.value = true;
            }, 1000);

        }

        async function getAdmissionRequest() {
          let token = sessionStorage.getItem("login");
          await getAdmissionRequestOrCreate(token, personId.value).then((response) => {
            admission_registration_date.value = response.data.response.admission_request.created_at
            admission_status.value = response.data.response.admission_request.status;
            if (response.data.response.admission_request != null) {
              admissionRequestStatus.value = admission_status.value == 'en_revision' || admission_status.value == 'completada'? false : true
            } else {
              admissionRequestStatus.value = false;
            }
            store.dispatch('setAvailableForRegistration', admissionRequestStatus.value);
          }).catch((error) => {
            admissionRequestStatus.value = false;
            store.dispatch('setAvailableForRegistration', admissionRequestStatus.value);
          });
        }

        async function getDataClient(){
          if(accountType.value == "member" || accountType.value == "cabsa_member")
          {
            const auth = getTokenDecoden();
            personId.value = auth.obj.person["id"];
            await getAdmissionRequest();
          }
        }

        function goToLoan(){
          if(!isLoanCandidate.value) return showMessage();

          router.push(`/${account.value}/credito`);
        }

        function showMessage(){
          Swal.fire({
            title: "Aviso",
            // title: "Comunicate con nosotros",
            // html: "Usted no es candidato para una solicitud de préstamo, favor de contactarse con servicio al cliente.",
            // html: "Puedes hacerlo a través de los siguientes canales: <br> <b> Tel: </b> (644) 416-4346 <br> <b> E-mail: </b> clientes@foncabsa.com <br><br> Horario de atención: <br> 8:00 A.M a 3:00 P.M de Lunes a Viernes",
            html: "Solicite términos y condiciones para obtener un crédito a través de nuestro correo electrónico <b> clientes@foncabsa.com</b>, o a través de nuestro número directo de contacto <b>(644) 416-4346</b>, en donde con gusto le atenderemos de lunes a viernes de 8 a 17 hrs.",
            icon: "info",
            confirmButtonText: "OK",
            confirmButtonColor: '#FEB72B'
          });
        }

        function open_hamburger(node){
            switch(node){
              case 'products':
                this.menu_items.report = false;
                this.menu_items.commission = false; 
                this.menu_items.products = !this.menu_items.products;
              break;
              case 'reports':
                this.menu_items.products = false; 
                this.menu_items.commission = false; 
                this.menu_items.report = !this.menu_items.report;
              break;
              case 'commission':
                this.menu_items.products = false;
                this.menu_items.report = false;
                this.menu_items.commission = !this.menu_items.commission;  
              break;
            }
          }

        return { router, missingData, organizations, logout, fullName, role, accountType, account, load, personId, admission_status, admissionRequestStatus, store, missingData, organizations, isLoanCandidate, goToLoan, clientId, menu_items, open_hamburger};
      },
  }
  </script>

  <style scoped>
  .avatar {
    padding: 2em 0.5em;
    text-align: center;
  }
  .avatar img {
    margin:0 auto;
    width: 22%;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;

  }
  .avatar h2 {
    font-weight: normal;
    margin-bottom: 0;
  }
  /* span {
    color: #fff;
    font-family:"Lato";
  }
  span:hover {
    color: #821740;
  } */
  ul li a {
    text-decoration:none;
  }
  .dropdown-content {
    padding: 0;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    text-align: left;
    min-width: 230px;
    border-width: 1px;
    border-style: solid;
    border-color:#85C875;
    box-shadow: 0 0 5px #9ecaed;
    z-index: 1;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .accordion-menu {
    width: 100%;
    max-width: 350px;
    /* background: #33414E; */
    border-radius: 4px;
  }

  .accordion-menu li a{
      text-decoration: none;
  }
  .accordion-menu li.open .dropdownlink {
    color: #727272;
  }

  .accordion-menu li.open .dropdownlink .fa-chevron-down {
      transform: rotate(180deg);
  }

  .accordion-menu li:last-child .dropdownlink {
    border-bottom: 0;
  }
  .dropdownlink {
    cursor: pointer;
    display: block;
    padding: 11px 11px 11px 5px;
    font-size: 15px;
    /* color: #FFF; */
    position: relative;
    transition: all 0.4s ease-out;
  }

  .dropdownlinkSubMenuItem {
    cursor: pointer;
    display: block;
    padding: 15px 15px 15px 8px;
    font-size: 15px;
    /* color: #FFF; */
    position: relative;
    transition: all 0.4s ease-out;
  }

  .dropdownlink i {
      position: absolute;
      top: 17px;
      left: 16px;
  }

  .dropdownlink .fa-chevron-down {
      right: 12px;
      left: auto;
  }

  .submenuItems {
    display: none;
    background: #FFF;
    width: 100%;
  }

  .submenuItems li a {
    text-decoration: none;
  }

  .submenuItems a {
    display: block;
    color: rgb(129, 0, 66);
    font-size: 12px;
    padding: 12px 12px 12px 50px;
    transition: all 0.4s ease-out;
  }

  .submenuItems a:hover {
    color: white;
    transition: all 0.4s ease-out;
  }

  .arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .titleHeader {
    display: block;
  }
  .titleMobil {
    display: none;
  }
  .searchBoxContent {
    display: none;
  }
  @media screen and (max-width: 1280px) {
    .avatar img {
      margin:0 auto;
      width:20%;
      height:50px;
    }
  }
  @media screen and (max-width: 1200px) {
    .avatar img {
      margin:0 auto;
      width:25%;
      height:50px;
    }
  }
  @media screen and (max-width: 1000px) {
    .avatar img {
      margin:0 auto;
      width:30%;
      height:50px;
    }
  }
  @media screen and (max-width: 640px) {
    .avatar img {
      margin:0 auto;
      width:50%;
      height:40px;
    }
  }
  @media screen and (max-width: 779px) {
    .titleHeader {
      display: none !important;
    }
    .titleMobil {
      display: block;
    }
    .subHeader {
      display: none;
    }
    .subTitleHeader {
      display: none;
    }
    .dropdownlink {
      padding: 5px 11px 5px 5px;
    }
    .dropdownlinkSubMenuItem {
      padding: 5px 11px 5px 5px;
    }
    .submenuItems a {
      padding: 12px;
      text-align: center;
    }
    .searchBoxContent {
      margin-top:20px;
      display: block;
      margin-bottom: 80px;
    }
  }
  @media screen and (max-width: 499px) {
    .avatar img {
      margin:0 auto;
      width:60%;
      height:40px;
    }
  }

  @media (max-width: 767px) {
    .menu_lateral_principal {
      display: none;
    }
    .titleMenu {
      display: none;
    }
  }
  </style>